<template>
  <div class="page">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="auForm"
    >
      <el-form-item label="投诉产品" prop="type">
        <el-select size="medium" v-model="ruleForm.type" placeholder="请选择投诉产品">
          <el-option
            v-for="it in types"
            :key="it.id"
            :label="it.title"
            :value="it.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投诉链接" prop="link" v-if="['web'].includes(ruleForm.type)">
        <el-input size="medium" type="text" v-model="ruleForm.link"></el-input>
      </el-form-item>
      <el-form-item label="投诉原因" prop="reason">
        <el-select size="medium" v-model="ruleForm.reason" placeholder="请选择投诉产品">
          <el-option
            v-for="it in reasons"
            :key="it.id"
            :label="it.title"
            :value="it.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投诉链接" prop="link" v-if="['web'].includes(ruleForm.type)">
        <el-input size="medium" type="text" v-model="ruleForm.link"></el-input>
      </el-form-item>
      <el-form-item size="medium" label="详细说明" prop="desc">
        <el-input type="textarea" v-model="ruleForm.desc"></el-input>
      </el-form-item>
      <el-form-item label="联系邮箱" prop="email">
        <el-input size="medium" placeholder="留下您的联系邮箱，以便我们将处理结果通知给您，我们会严格保密" type="text" v-model="ruleForm.email"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" @click="submitForm('ruleForm')">
          提交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        type: "",
        link: "",
        desc: "",
        reason: "",
        email: "",
      },
      //投诉产品
      types: [
        {
          id: "web",
          title: "土豆查官网",
        },
        {
          id: "extension",
          title: "浏览器插件",
        },
      ],
      //投诉原因
      reasons: [
        {
          id: 1,
          title: "意见反馈",
        },
        {
          id: 2,
          title: "侵权",
        },
        {
          id: 3,
          title: "政治敏感",
        },
        {
          id: 4,
          title: "恐怖血腥",
        },
        {
          id: 6,
          title: "赌博",
        },
        {
          id: 7,
          title: "谣言",
        },
        {
          id: 8,
          title: "隐私泄露",
        },
        {
          id: 9,
          title: "其他",
        },
      ],
      rules: {
        type: [
          { required: true, message: "请选择投诉产品", trigger: "change" },
        ],
        reason: [
          { required: true, message: "请选择投诉原因", trigger: "change" },
        ],
        link: [{ required: true, message: "请输入投诉链接", trigger: "blur" }],
        desc: [{ required: true, message: "请填写详细说明", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.auForm{
    display: flex;
    flex-flow: column nowrap;
    &>*{
      margin-bottom: 45px;
    }
}
.page {
  width: 560px;
  margin: auto;
  padding: 60px 0;
  min-height: 420px;
}
</style>